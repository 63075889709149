import React, { useEffect, useState } from "react";
import "./App.css";
import HomeScreen from "./screens/HomeScreen";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginScreen from "./screens/LoginScreen";
import { auth } from "./firebase";
import { useDispatch, useSelector } from "react-redux";
import { login, logout, selectUser } from "./features/userSlice";
import ProfileScreen from "./screens/ProfileScreen";
import BrowseScreen from "./screens/BrowseScreen";

function App() {
  // Remove the user and dispatch state
  // const user = useSelector(selectUser);
  // const dispatch = useDispatch();

  // Remove the loading state
  // const [loading, setLoading] = useState(true);

  // Remove the useEffect hook for authentication
  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged((userAuth) => {
  //     if (userAuth) {
  //       // Logged in
  //       dispatch(
  //         login({
  //           uid: userAuth.uid,
  //           email: userAuth.email,
  //         })
  //       );
  //     } else {
  //       // Logged out
  //       dispatch(logout());
  //     }
  //
  //     // Set loading to false once authentication status is determined
  //     setLoading(false);
  //   });
  //
  //   return unsubscribe;
  // }, [dispatch]);

  // Remove the loading indicator
  // if (loading) {
  //   return (
  //     <div className="app">
  //
  //     </div>
  //   );
  // }

  // return (
  //  <div className="app">
  //    <Router>
        
  //      {/* Render the LoginScreen if there is no user */}
  //      {!user ? (
  //        <LoginScreen />
  //      ) : (
  //        <Routes>
  //          {/* Use the 'element' prop to render the components */}
  //          <Route path="/profile" element={<ProfileScreen />} />
  //          <Route path="/" element={<HomeScreen />} />
  //          {/* <Route path="/page/:movieId" element={<PageScreen />} /> {/* Dynamic parameter */}
  //          <Route path="/browse" element={<BrowseScreen />} />
  //        </Routes>
  //      )}
  //    </Router>
  //  </div>
  //);


  return (
    <div className="app">
      <Router>
        {/* Render the HomeScreen directly */}
        <HomeScreen />
      </Router>
    </div>
  );
}

export default App;
