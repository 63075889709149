import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // For Firestore, adjust if using other Firebase services
import { getAuth } from "firebase/auth"; // Import getAuth for authentication


const firebaseConfig = {
  apiKey: "AIzaSyDlbizcOcAerSO3FNcn_4szCPwbCIqwU9Y",
  authDomain: "showtrove-da0cd.firebaseapp.com",
  projectId: "showtrove-da0cd",
  storageBucket: "showtrove-da0cd.appspot.com",
  messagingSenderId: "405735782340",
  appId: "1:405735782340:web:eec8fabe8eee57255b9691",
  measurementId: "G-1D1C782Y79"
};

// Initialize Firebase app
const firebaseApp = initializeApp(firebaseConfig);

// Create Firestore instance
const db = getFirestore(firebaseApp);

// Create Auth instance for authentication
const auth = getAuth(firebaseApp);

// Export the instances you want to use in other parts of your app
export { db, auth };