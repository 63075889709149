import React from "react";
import Banner from "../Banner";
import "./HomeScreen.css";
import Nav from "../Nav";
import requests from "../Requests";
import Row from "../Row";

function HomeScreen() {
  
  return  (
    <div className="homeScreen">
      <Nav />
 
      <Banner />

      <Row title="Trending Now" fetchUrl={requests.fetchTrending} isLargeRow />
      <Row title="Top Rated" fetchUrl={requests.fetchTopRated} isLargeRow />
      <Row title="Netflix Originals" fetchUrl={requests.fetchNetflixOriginals} isLargeRow />
      <Row title="Action" fetchUrl={requests.fetchActionMovies} isLargeRow />
      <Row title="Crime" fetchUrl={requests.fetchCrime} isLargeRow />
      <Row title="Comedy" fetchUrl={requests.fetchComedyMovies} isLargeRow />
      <Row title="Horror" fetchUrl={requests.fetchHorrorMovies} isLargeRow />
      {/*<Row title="Drama" fetchUrl={requests.fetchDrama} isLargeRow />*/}
      <Row title="Romance" fetchUrl={requests.fetchRomanceMovies} isLargeRow />
      <Row title="Family" fetchUrl={requests.fetchFamily} isLargeRow />
      {/*<Row title="Animation" fetchUrl={requests.fetchAnimation} isLargeRow />
      <Row title="SciFi" fetchUrl={requests.fetchSciFi} isLargeRow />
      <Row title="Mystery" fetchUrl={requests.fetchMystery} isLargeRow />*/}
      <Row title="Western" fetchUrl={requests.fetchWestern} isLargeRow />
      {/*<Row title="War" fetchUrl={requests.fetchWar} isLargeRow />*/}
      <Row title="History" fetchUrl={requests.fetchHistory} isLargeRow />
      <Row title="Documentaries" fetchUrl={requests.fetchDocumentaries} isLargeRow />
      
      {/*
      <div className="footer">
        <p>Founder : <a href="https://www.linkedin.com/in/jesugbenga-omoniwa">Jesugbenga Omoniwa</a></p>
      </div>*/}
    </div>
  );
}

export default HomeScreen;
