import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Nav.css";
import pic from "./profile pic.png";
import logo from "./showtrove-temp.png"
import { Link } from 'react-router-dom';

function Nav() {
  const [show, handleShow] = useState(false);
  const navigate = useNavigate(); // Use the useNavigate hook

  const transitionNavBar = () => {
    if (window.scrollY > 100) {
      handleShow(true);
    } else {
      handleShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", transitionNavBar);
    return () => window.removeEventListener("scroll", transitionNavBar);
  }, []);

  return (
    <div className={`nav ${show && "nav__black"}`}>
      <div className="nav__contents">
        <img
          onClick={() => window.location.reload()}
          className="nav__logo"
          src={logo}
          alt=""
        />
      {/*}
        <img
          onClick={() => navigate("/profile")} 
          className="nav__avatar"
          src={pic}
          alt=""
        />

        <div class="input-wrapper">
          <button class="icon"> 
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="25px" width="25px">
              <path stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#fff" d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"></path>
              <path stroke-linejoin="round" stroke-linecap="round" stroke-width="1.5" stroke="#fff" d="M22 22L20 20"></path>
            </svg>
          </button>
          <input placeholder="search.." class="input" name="text" type="text" />
        </div>
        */}
        
       
        <ul class="socail-media">
          <li>
          
              <a href="http://www.youtube.com/@Showtrove">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                  <path fill="#000" d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
                </svg>
              </a>
           
          </li>

          <li>
            <a href="https://www.instagram.com/showtrove/#">
                    <svg viewBox="0 0 20 20" height="20" width="20" xmlns="http://www.w3.org/2000/svg" id="instagram">
                  <g transform="translate(15.354 3.707)" data-name="Group 64" id="Group_64">
                    <g data-name="Group 63" id="Group_63">
                      <path fill="#00" transform="translate(-392.401 -94.739)" d="M392.871,94.739a.47.47,0,1,0,.47.47A.47.47,0,0,0,392.871,94.739Z" data-name="Path 5" id="Path_5"></path>
                    </g>
                  </g>
                  <g transform="translate(5.837 5.837)" data-name="Group 66" id="Group_66">
                    <g data-name="Group 65" id="Group_65">
                      <path fill="#000" transform="translate(-145.804 -145.804)" d="M149.967,145.8a4.163,4.163,0,1,0,4.163,4.163A4.168,4.168,0,0,0,149.967,145.8Z" data-name="Path 6" id="Path_6"></path>
                    </g>
                  </g>
                  <g data-name="Group 68" id="Group_68">
                    <g data-name="Group 67" id="Group_67">
                      <path fill="#000" d="M14.517,0H5.483A5.489,5.489,0,0,0,0,5.483v9.035A5.489,5.489,0,0,0,5.483,20h9.035A5.489,5.489,0,0,0,20,14.517V5.483A5.489,5.489,0,0,0,14.517,0ZM10,15.486A5.486,5.486,0,1,1,15.486,10,5.492,5.492,0,0,1,10,15.486Zm5.814-9.633A1.667,1.667,0,1,1,17.48,4.186,1.669,1.669,0,0,1,15.814,5.853Z" data-name="Path 7" id="Path_7"></path>
                    </g>
                  </g>
                </svg>

            </a>
          </li>

          <li>
            <a href="https://www.snapchat.com/add/showtrove?share_id=KGRqa4lMfYo&locale=en-CA">
              <svg viewBox="0 0 19.838 18.6" height="18.6" width="19.838" xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(0 -15.988)" data-name="svgexport-10 (13)" id="svgexport-10_13_">
            <g transform="translate(0 15.988)" data-name="Group 10" id="Group_10">
              <g transform="translate(0 0)" data-name="Group 9" id="Group_9">
                <path fill="#000" transform="translate(0 -15.988)" d="M19.449,29.877a5.73,5.73,0,0,1-3.9-3.269.188.188,0,0,0-.011-.022.82.82,0,0,1-.093-.675c.145-.34.7-.516,1.068-.632.107-.033.207-.066.29-.1a2.245,2.245,0,0,0,.791-.472.751.751,0,0,0,.241-.541.851.851,0,0,0-.637-.738,1.152,1.152,0,0,0-.432-.082.961.961,0,0,0-.4.082,2.255,2.255,0,0,1-.827.236.759.759,0,0,1-.274-.06c.007-.124.015-.252.024-.383l0-.057a9.821,9.821,0,0,0-.253-4.005,5.388,5.388,0,0,0-1.214-1.737,5.076,5.076,0,0,0-1.452-.957,5.737,5.737,0,0,0-2.265-.479h-.05l-.341,0a5.759,5.759,0,0,0-2.268.479A5.048,5.048,0,0,0,6,17.426,5.4,5.4,0,0,0,4.8,19.16a9.829,9.829,0,0,0-.254,4v0c.009.144.019.294.027.44a.8.8,0,0,1-.325.061,2.151,2.151,0,0,1-.884-.237.794.794,0,0,0-.336-.069,1.188,1.188,0,0,0-.594.166.822.822,0,0,0-.42.537.741.741,0,0,0,.283.7,2.438,2.438,0,0,0,.739.423c.084.033.185.064.29.1.367.117.924.293,1.069.632a.826.826,0,0,1-.094.675.176.176,0,0,0-.01.022,6.566,6.566,0,0,1-1,1.545,5.608,5.608,0,0,1-1.205,1.064,4.435,4.435,0,0,1-1.693.658A.463.463,0,0,0,0,30.359a.587.587,0,0,0,.048.2h0a1.239,1.239,0,0,0,.672.574,7.675,7.675,0,0,0,1.941.5,2.354,2.354,0,0,1,.119.427c.032.149.066.3.114.465a.508.508,0,0,0,.532.387,2.59,2.59,0,0,0,.456-.063,5.189,5.189,0,0,1,1.04-.118,4.521,4.521,0,0,1,.744.063,3.534,3.534,0,0,1,1.39.718A4.5,4.5,0,0,0,9.8,34.587q.05,0,.1,0c.04,0,.091,0,.143,0a4.507,4.507,0,0,0,2.74-1.066h0a3.546,3.546,0,0,1,1.39-.717,4.521,4.521,0,0,1,.744-.063,5.213,5.213,0,0,1,1.04.11,2.514,2.514,0,0,0,.456.056h.022a.5.5,0,0,0,.51-.381c.047-.16.081-.309.114-.461a2.435,2.435,0,0,1,.118-.424,7.728,7.728,0,0,0,1.941-.5,1.244,1.244,0,0,0,.671-.573.589.589,0,0,0,.05-.2A.461.461,0,0,0,19.449,29.877Z" data-name="Path 6565" id="Path_6565"></path>
              </g>
            </g>
          </g>
        </svg>

            </a>
          </li>

          <li>
            <a href="https://www.tiktok.com/@showtrove?_r=1&_d=e8h9ge7g845ckm&sec_uid=MS4wLjABAAAANdgs1hPhjP7Z4Q6UirN_5Xzx8XE-enAJhmNfScuG2BMZDxDxSX0MYGUWq4JgnqQ9&share_author_id=7248427483731674118&sharer_language=en&source=h5_m&u_code=e8h9h2bk7c6ij4&timestamp=1718599927&user_id=7248427483731674118&sec_user_id=MS4wLjABAAAANdgs1hPhjP7Z4Q6UirN_5Xzx8XE-enAJhmNfScuG2BMZDxDxSX0MYGUWq4JgnqQ9&utm_source=copy&utm_campaign=client_share&utm_medium=android&share_iid=7378676338242569989&share_link_id=1ff0b286-9ea3-4368-9119-611f58daee1a&share_app_id=1233&ugbiz_name=ACCOUNT&ug_btm=b8727%2Cb0229&social_share_type=5&enable_checksum=1">
              <svg viewBox="0 0 19.738 22.466" height="22.466" width="19.738" xmlns="http://www.w3.org/2000/svg" data-name="Group 101" id="Group_101">
          <path fill="#000" transform="translate(-31.423 -0.39)" d="M51.151,6.015a5.661,5.661,0,0,1-3.42-1.143A5.662,5.662,0,0,1,45.469.39H41.8V10.414l0,5.49a3.325,3.325,0,1,1-2.281-3.151V9.029a7.218,7.218,0,0,0-1.058-.078,7.034,7.034,0,0,0-5.286,2.364,6.893,6.893,0,0,0,.311,9.505,7.158,7.158,0,0,0,.663.579,7.035,7.035,0,0,0,4.312,1.458,7.219,7.219,0,0,0,1.058-.078,7.011,7.011,0,0,0,3.917-1.959,6.868,6.868,0,0,0,2.06-4.887l-.019-8.2a9.3,9.3,0,0,0,5.688,1.933V6.014h-.011Z" data-name="Path 6566" id="Path_6566"></path>
        </svg>

            </a>
          </li>
          {/*}
          <li>
            <a href="#">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
              <path fill="#000" d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
            </svg>


            </a>
          </li>

          <li>
            <a href="#">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#000" class="bi bi-envelope-at-fill" viewBox="0 0 16 16">
                <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2zm-2 9.8V4.698l5.803 3.546zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.5 4.5 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586zM16 9.671V4.697l-5.803 3.546.338.208A4.5 4.5 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671"/>
                <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791"/>
              </svg>
            </a>
          </li>*/}
        </ul>
                
      </div>
    </div>
  );
}

export default Nav;
