// Typically we should store in {process.env.API_KEY}
const API_KEY = "a86144f6893336dbde9ec817fb31e3c2";

const requests = {
    fetchTrending: `/trending/all/day?api_key=${API_KEY}&language=en-US`,
    fetchNetflixOriginals: `/discover/tv?api_key=${API_KEY}&with_networks=213`,
    fetchTopRated: `/movie/top_rated?api_key=${API_KEY}&language=en-US`,
    fetchActionMovies: `/discover/movie?api_key=${API_KEY}&with_genres=28`,
    fetchCrime: `/discover/movie?api_key=${API_KEY}&with_genres=80`,
    fetchComedyMovies: `/discover/movie?api_key=${API_KEY}&with_genres=35`,
    fetchHorrorMovies: `/discover/movie?api_key=${API_KEY}&with_genres=27`,
    fetchDrama: `/discover/movie?api_key=${API_KEY}&with_genres=18`,
    fetchRomanceMovies: `/discover/movie?api_key=${API_KEY}&with_genres=10749`,
    fetchFamily: `/discover/movie?api_key=${API_KEY}&with_genres=10751`,
    fetchAnimation: `/discover/movie?api_key=${API_KEY}&with_genres=16`,
    fetchSciFi: `/discover/movie?api_key=${API_KEY}&with_genres=878`,
    fetchMystery: `/discover/movie?api_key=${API_KEY}&with_genres=9648`,
    fetchWestern: `/discover/movie?api_key=${API_KEY}&with_genres=37`,
    fetchWar: `/discover/movie?api_key=${API_KEY}&with_genres=10752`,
    fetchHistory: `/discover/movie?api_key=${API_KEY}&with_genres=36`,
    fetchDocumentaries: `/discover/movie?api_key=${API_KEY}&with_genres=99`,
    
};


export default requests;