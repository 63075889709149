import React, { useEffect, useState } from "react";
import "./Banner.css";
import axios from "./axios";
import requests from "./Requests";
import { useNavigate} from "react-router-dom";
import Modal from "react-modal";
import cancel from "./cancel.png";

Modal.setAppElement("#root"); // Ensure the modal is rendered in the root element

const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "34%", // Adjust the width here
      height: "auto", // Adjust the height here
      maxWidth: "100%", // Set a maximum width to prevent overflow
      overflow: "visible", // Remove scrolling
      display: "flex", // Use flexbox to center the content
      justifyContent: "center", // Center the content horizontally
      alignItems: "flex-start", // Position the content at the top
      borderRadius: "10px", // Make the corners rounded
      backgroundColor: "#111", // Set the background color
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.5)", // Add a drop shadow
      border: "1px solid #111", // Add a border with color #111
    },
  };
  
// Add a media query for screens smaller than 700px
if (window.matchMedia("screen and (max-width: 700px)").matches) {
    customStyles.content.width = "84%";
    customStyles.content.fontSize = "0.78rem"; 
  }
  

function Banner() {
    const [movie, setMovie] = useState([]);
    const [selectedMovie, setSelectedMovie] = useState(null); // State to hold the selected movie
    const [modalIsOpen, setIsOpen] = useState(false);

    const navigate = useNavigate(); // Use the useNavigate hook


    useEffect(() => {
        async function fetchData() {
            const request = await axios.get(requests.fetchTrending);
            const randomIndex = Math.floor(Math.random() * request.data.results.length);
            const randomMovie = request.data.results[randomIndex];

            // Check if the movie meets the conditions
            if (randomMovie.backdrop_path) {
                setMovie(randomMovie);
                setSelectedMovie(randomMovie);
            } else {
                // If the movie doesn't meet the conditions, fetch data again
                fetchData();
            }
            return request;
        }

        fetchData();
    }, [])

    function truncate(string, n){
        return string?.length > n ? string.substr(0, n - 1) + '...': string; /*? for incase the string isn't there */
    }

    const handleMovieClick = (movie) => {
        setSelectedMovie(movie);
        navigate(`/page/${movie.id}`);
        setIsOpen(true); // Open the modal when a More Information is clicked
      };
    
    const closeModal = () => {
    setIsOpen(false);
    };

  return (
    <header
    className="banner" 
    style={{
        backgroundSize: "cover",
        backgroundImage: `url("https://image.tmdb.org/t/p/original/${movie?.backdrop_path}")`,
        backgroundPosition: "center center",  /*Keeps it central */
    }}>
        
        <div className="banner__contents">
            <h1 className="banner__title">
                {movie?.title || movie?.name || movie?.original_name}
            </h1>
            <h1 className="banner__description">
                {truncate(movie?.overview, 200)}
            </h1>
            <div className="banner__buttons">
                {/*<button className="banner__button"> + Add to Watchlist </button> 
                <button className="banner__button" onClick={() => handleMovieClick(movie)}>More information</button>*/}
            </div>
        </div>

        <div className="banner__fadeBottom"/> 

        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
            {selectedMovie && (
            <div className="modal__content" style={{ display: "flex", flexDirection: "column", justifyContent: "center", maxWidth: "100%", padding: "0px"}}>
                <button
                    style={{ position: "absolute", 
                    top: "8px", 
                    right: "-24px", 
                    border: "none", 
                    background: "none", 
                    cursor: "pointer",
                    }}
                    onClick={closeModal}
                >
                <img
                    src={cancel}
                    alt="Close Icon"
                    style={{ width: "32%", height: "32%" }}
                />
            </button>
                
            <img
                className="modal__backdrop"
                src={`https://image.tmdb.org/t/p/original/${selectedMovie.backdrop_path}`}
                alt={selectedMovie.title}
                style={{maxHeight: "100%", objectFit: "contain", width: "100%"}} 
            />
            <h2 className="modal__title" style={{ color: "white" }}>{selectedMovie.title || selectedMovie.name || selectedMovie.original_name}</h2>
            <p className="modal__overview" style={{ color: "white" }}>{selectedMovie.overview}</p>
            </div>
            )}
        </Modal>



    </header>
  );
}

export default Banner;
