import React, { useEffect, useState } from "react";
import axios from "./axios";
import "./Row.css";
import { useNavigate} from "react-router-dom";
import Modal from "react-modal";
import cancel from "./cancel.png";
import left_arrow from "./arrow_back.png"
import right_arrow from "./arrow_forward.png"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "34%", // Adjust the width here
    height: "auto", // Adjust the height here
    maxWidth: "100%", // Set a maximum width to prevent overflow
    overflow: "visible", // Remove scrolling
    display: "flex", // Use flexbox to center the content
    justifyContent: "center", // Center the content horizontally
    alignItems: "flex-start", // Position the content at the top
    borderRadius: "10px", // Make the corners rounded
    backgroundColor: "#111", // Set the background color
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.5)", // Add a drop shadow
    border: "1px solid #111", // Add a border with color #111
  },
};

// Add a media query for screens smaller than 700px
if (window.matchMedia("screen and (max-width: 700px)").matches) {
  customStyles.content.width = "84%";
  customStyles.content.fontSize = "0.78rem"; 
}


function Row({ title, fetchUrl, isLargeRow = false }) {
  const [movies, setMovies] = useState([]);
  const [selectedMovie, setSelectedMovie] = useState(null); // State to hold the selected movie
  const [modalIsOpen, setIsOpen] = useState(false);


  const base_url = "https://image.tmdb.org/t/p/original/";
  
  const navigate = useNavigate(); // Use the useNavigate hook

  useEffect(() => {
    async function fetchData() {
      const request = await axios.get(fetchUrl);
      setMovies(request.data.results);
      return request;
    }

    fetchData();
  }, [fetchUrl]);

  const handleMovieClick = (movie) => {
    setSelectedMovie(movie); // Set the selected movie in state
    navigate(`/page/${movie.id}`);
    setIsOpen(true); // Open the modal when a poster is clicked
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="row">
      <h2 className="title">{title}</h2>

      <div className="row__posters">
        {movies.map(
          (movie) =>
            ((isLargeRow && movie.poster_path) ||
              (!isLargeRow && movie.backdrop_path)) && (
              <img
                onClick={() =>  handleMovieClick(movie)} // Pass the clicked movie to the handler
                className={`row__poster ${isLargeRow && "row__posterLarge"}`}
                key={movie.id}
                src={`${base_url}${isLargeRow ? movie.poster_path : movie.backdrop_path}`}
                alt={movie.name}
              />
            )
        )}
      </div>
      
      {/*Arrows for scrolling
      <div class="row__arrows">
        <button class="row__arrow--left">
          <img
            src={left_arrow}
            alt="Left Arrow"
            style={{ width: "30%", height: "80%" }}
          />
        </button>
        <button class="row__arrow--right">
          <img
            src={right_arrow}
            alt="Right Arrow"
            style={{ width: "30%", height: "80%" }}
          />
        </button>
      </div>*/}

      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
        {selectedMovie && (
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", maxWidth: "100%", padding: "0px"}}>
            <button
              style={{ position: "absolute", 
              top: "8px", 
              right: "-24px", 
              border: "none", 
              background: "none", 
              cursor: "pointer",
            }}
              onClick={closeModal}
            >
            <img
              src={cancel}
              alt="Close Icon"
              style={{ width: "32%", height: "32%" }}
            />
            </button>
            <img
              src={`${base_url}${selectedMovie.backdrop_path}`}
              alt={selectedMovie.title}
              style={{maxHeight: "100%", objectFit: "contain", width: "100%"}} // Reduce the maximum width and height here
            />
            <h3 style={{ color: "white" }}>{selectedMovie.title || selectedMovie.name || selectedMovie.original_name}</h3>
            <p style={{ color: "white" }}>{selectedMovie.overview}</p>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default Row;
